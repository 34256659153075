import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import Offers from "./Components/Offers";
import Banks from "./Components/banks";
import Testimonials from "./Components/Testimonials";
import Upload from "./Components/Upload";
import FileViewer from "./Components/ShowFiles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.main} />
        {/* <SlideCarousel />
         <Offers data={this.state.resumeData.Offers} /> */}
         <Portfolio data={this.state.resumeData.portfolio} />
         <Offers data={this.state.resumeData.Offers} />
         <Upload data={this.state.resumeData.main}/>
         <ToastContainer autoClose={3000} hideProgressBar />
         <About data={this.state.resumeData.main} />
         <Banks data={this.state.resumeData.banks}/>
         <Footer data={this.state.resumeData.main} />
         <FileViewer /> 
      </div>
    );
  }
}

export default App;
