import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
import listReactFiles from 'list-react-files'



// function importAll(r) {
//   return r.keys().map(r);
// }

// const images = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));
const file = 'http://localhost:3000/images/carousal/1.png'
const type = 'png'
const arrName = ['http://localhost:3000/images/carousal/1.png','http://localhost:3000/images/carousal/2.png','http://localhost:3000/images/carousal/3.png']
//listReactFiles('http://localhost:3000/images/carousal/').then(files => console.log(files))
// var find = require('list-files');
 
// find(function(result) {
//     console.log(result);
//     //=> './dirname/a.js'
//     //=> './dirname/b.js'
// }, {
//     dir: './Upload/*',
//     name: 'png'
// });

class ShowFiles extends Component {
  
    render() {
      
      //listReactFiles('http://localhost:3000/images/carousal/').then(files => console.log(files))
        return (       

         
           <FileViewer
             fileType={type}
             filePath={file}
             errorComponent={CustomErrorComponent}
             onError={this.onError}/>
                    
        );
      }
        
  
    onError(e) {
      //logger.logError(e, 'error in file-viewer');
    }
  
}

export default ShowFiles;
