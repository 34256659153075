import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";
//import ReactCardSlider from "react-card-slider-component";
import CarouselSlider from "react-carousel-slider";
 

let id = 0;
class Offers extends Component {

  

  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/offers/" + projects.image;      
    });

    let data = [
      {
        des: "2 - 5 Lakhs",
        imgSrc: "images/offers/1.png"
      },
      {
        des: "5 - 7.5 Lakhs",
        imgSrc: "images/offers/2.png"
      },
      {
        des: "7.5 - 10 Lakhs",
        imgSrc: "images/offers/3.png"
      },
      {
        des: "11 - 15 Lakhs",
        imgSrc: "images/offers/4.png"
      },
      {
        des: "16 - 20 Lakhs",
        imgSrc: "images/offers/5.png"
      },
      {
        des: "20 - 30 Lakhs",
        imgSrc: "images/offers/6.png"
      }
    ];
    
    /* Percantage to set height does not work well 
    in prop sliderBoxStyle here because we could 
    not init the height of parent element */
    
    let sliderBoxStyle = {
      height: "400px"
      , width: "100%"
      , background: "tranparent"
    };
    
    let itemsStyle = {
      height: "300px",
      width : "300px"
      , background: "#0762f9"
     
    };
    
    let textBoxStyle = {
       textAlign: "center"
       ,width:"100%"
       , background: "#0762f9"
       , fontSize: "30px"
       , fontWeight: "bolder"
    };
    
    let buttonSetting = {
       placeOn: "middle-inside"
       ,hoverEvent: true //,
      // , style: {
      //   left: {
      //     margin: "0px 0px 0px 10px"
      //   },
      //   right: {
      //     margin: "0px 10px 0px 0px"
      //   }
      // }
    };
    
    let manner = {
       autoSliding: {interval: "4s"}
      , duration: "0.3s"
    };
  
 

    return (
      <section id="Offers">
        <Fade left duration={1000} distance="40px">
         <h1>Assured Gifts</h1>
       
            {/* <CarouselSlider
             slideItems={data}
             manner={manner}
             buttonSetting={buttonSetting}
             sliderBoxStyle={sliderBoxStyle}
             itemsStyle={itemsStyle}
              textBoxStyle={textBoxStyle}
              /> */}

<div id="Offers-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf">
    <div class="banner-text">
      <div class="row">
        <div class="three columns">
          <div class="card card_red text-center">
            <div class="title">
              <h2>2 - 5</h2>
            </div>
            <div class="price">
              <h4><sup></sup>Lakhs</h4>
            </div>
            <div class="option">
              <ul>
                <li><img src="images/offers/1.png" ></img></li>
                <li>Branded Mixer</li>
                
              </ul>
            </div>
            <a href="#Upload">Apply Now</a>
          </div>
        </div>
        <div class="three columns">
          <div class="card card_violet text-center">
            <div class="title">
              <h2>5 - 7.5</h2>
            </div>
            <div class="price">
              <h4><sup></sup>Lakhs</h4>
            </div>
            <div class="option">
              <ul>
                <li><img style={{height:"160px"}} src="images/offers/2.png" ></img></li>
                <li>Branded Air Fryer</li>
                </ul>
            </div>
            <a href="#Upload">Apply Now</a>
          </div>
        </div>
        <div class="three columns">
          <div class="card card_violet text-center">
            <div class="title">
              <h2>7.5 - 10</h2>
            </div>
            <div class="price">
              <h4><sup></sup>Lakhs</h4>
            </div>
            <div class="option">
              <ul>
                <li><img style={{height:"160px"}} src="images/offers/3.png" ></img></li>
                <li>Branded Oven</li>
                </ul>
            </div>
            <a href="#Upload">Apply Now</a>
          </div>
        </div>
        <div class="three columns">
          <div class="card card_three text-center">
            <div class="title">
              <h2>10 - 15</h2>
            </div>
            <div class="price">
              <h4><sup></sup>Lakhs</h4>
            </div>
            <div class="option">
              <ul>
                <li><img style={{height:"160px"}} src="images/offers/4.png" ></img></li>
                <li>Branded Cycle </li>
                </ul>
            </div>
            <a href="#Upload">Apply Now</a>
          </div>
        </div>
        
      </div>
    </div>
  </div>

          
              {/* <CardSlider slides={slides}/> */}     
          
        </Fade>
      </section>
    );
  }
}

export default Offers;
