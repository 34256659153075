import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import ReactDOM from "react-dom";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import Slider from "react-slick";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

class Header extends Component {

  state = {
    goToSlide: 4,
    offsetRadius: 5,
    showNavigation: false,
    config: config.gentle
  };

  slides = [
    
    {
      key: uuidv4(),
      content: <img src="images/carousal/01.png" alt="2" />
    },
    {
      key: uuidv4(),
      content: <img src="images/carousal/2.png" alt="3" />
    },
    {
      key: uuidv4(),
      content: <img src="images/carousal/3.png" alt="4" />
    },
    {
      key: uuidv4(),
      content: <img src="images/carousal/4.png" alt="4" />
    },
    
    {
      key: uuidv4(),
      content: <img src="images/carousal/2.png" alt="8" />
    }
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {
    if (!this.props.data) return null;

    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const project = this.props.data.project;
    const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
  
  
    return (

      <header id="home">
        {/* <ParticlesBg type="circle" bg={true} /> */}
        <div className="row" style={{  height : "100px" }}>
          <div className="four columns">
            <img style={{ width : "466px", height : "112px", position: "absolute", left: "-100px" }} src="./images/loankaaran.png" />
          </div>
          <div className="six columns">
            
            <nav id="nav-wrap">
              <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
              Show navigation
              </a>
              <a className="mobile-btn" href="#home" title="Hide navigation">
                Hide navigation
              </a>          
              <ul id="nav" className="nav">
                <li className="current">
                  <a className="smoothscroll" href="#home">
                    Home 
                  </a>
                </li>
                <li>
                  <a className="smoothscroll" href="#Offers">
                    Offers 
                  </a>
                </li> 
                <li>
                  <a className="smoothscroll" href="#Upload">
                    Apply loan 
                  </a>
                </li>
                <li>
                  <a className="smoothscroll" href="#about">
                    About
                  </a>
                </li>           
                <li >
                  {/* <a className="smoothscroll" href="#about">
                    info@loankaaran.com
                  </a> */}
                </li>
              </ul>
            </nav>
          
          </div>
          {/*<div className="four columns">
             <img style={{ width : "300px", height : "100px", position: "absolute", left: "150px" }} src="./images/loankaaran.png" /> 
          </div>*/}
        </div>
        
        

        
             
                     {/* <Carousel slides={this.slides}
                        goToSlide={this.state.goToSlide}
                        offsetRadius={this.state.offsetRadius}
                        showNavigation={this.state.showNavigation}
                        animationConfig={this.state.config}  /> */}
          <div className="slider" >
              <AutoplaySlider
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={6000}
                          >
                    <div data-src="images/carousal/1.png" />
                    <div data-src="images/carousal/2.png" />
                    <div data-src="images/carousal/3.jpg" />
                    <div data-src="images/carousal/4.png" />
                </AutoplaySlider>
          </div>         
                   
                          
           

                {/* <a className="smoothscroll" href="#Offers">
                  <img style = {{ width : "100px", height : "100px" }} src="images/gifts.gif" />
                </a> */}
          
            
            
            {/* <ReactImagesCarousel images={sliderData} sliderBg="#fff" paginationBg="#0D0D0D"/> */}
            
              {/* <div>
                <Fade bottom duration={2000}>
                  <ul className="social">
                    <a href="#about" className="button btn project-btn">
                      About Us
                    </a>
                    <a href="#Upload" className="button btn github-btn">
                      Apply loan
                    </a>
                  </ul>
                </Fade>
              </div> */}
            {/* <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr /> */}

      </header>
    );
  }
}

export default Header;
