import React, {useState, Component, useRef }  from "react";
import axios  from "axios";
import { toast } from "react-toastify";
import emailjs from '@emailjs/browser';

class Upload extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: "",
      AadharFile: "",
      PanFile: "",
      PhotoFile: "",
      Pay1File: "",
      Pay2File: "",
      Pay3File: "",
      BankFile: "",
      OtherFile: "",
      responseArray: [],
      name: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAadharInputChange = this.handleAadharInputChange.bind(this);
    this.handlePanInputChange = this.handlePanInputChange.bind(this);
    this.handlePhotoInputChange = this.handlePhotoInputChange.bind(this);
    this.handlePay1InputChange = this.handlePay1InputChange.bind(this);
    this.handlePay2InputChange = this.handlePay2InputChange.bind(this);
    this.handlePay3InputChange = this.handlePay3InputChange.bind(this);
    this.handleBankInputChange = this.handleBankInputChange.bind(this);
    this.handleOtherInputChange = this.handleOtherInputChange.bind(this);
   
  }

  handleInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      //selectedFile: event.target.files,
      name: event.Name.value,
      responseArray:[]
    });
  }

  handleAadharInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      AadharFile: event.target.files,
      responseArray:[]
    });
  }
 
  handlePanInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      PanFile: event.target.files,
      responseArray:[]
    });
  }

  handlePhotoInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      PhotoFile: event.target.files,
      responseArray:[]
    });
  }

  handlePay1InputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      Pay1File: event.target.files,
      responseArray:[]
    });
  }

  handlePay2InputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      Pay2File: event.target.files,
      responseArray:[]
    });
  }

  handlePay3InputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      Pay3File: event.target.files,
      responseArray:[]
    });
  }

  handleBankInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      BankFile: event.target.files,
      responseArray:[]
    });
  }

  handleOtherInputChange(event) {
    //const [selectedFile, setArray] = useState([])
    this.setState({      
      OtherFile: event.target.files,
      responseArray:[]
    });
  }

  onSubmit() {
    // if (!this.state.selectedFile) {
    //   alert("Please select a file!");
    //   return false;
    // }


    const data = new FormData();

      data.append("name", this.state.name)
      
      data.append("file[]", this.state.AadharFile[0]);
    
      data.append("file[]", this.state.PanFile[0]);
    
      data.append("file[]", this.state.PhotoFile[0]);
   
      data.append("file[]", this.state.Pay1File[0]);
    
      data.append("file[]", this.state.Pay2File[0]);
    
      data.append("file[]", this.state.Pay3File[0]);
    
    for (let i = 0; i < this.state.BankFile.length; i++) {
      data.append("file[]", this.state.BankFile[i]);
    }
    for (let i = 0; i < this.state.OtherFile.length; i++) {
      data.append("file[]", this.state.OtherFile[i]);
    }

    let url = "/Upload.php";

    axios
      .post(url, data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        //this.setState({ responseArray: res.data });
        //this.sendEmail();
        this.sendEmail();
        this.resetFile();
        this.setState({});
      },error=>{
        //alert(error);
        toast.error("Upload failed! Please try again.");
      });



  }

  resetFile() {
    // Reset file input control
    document.getElementsByName("Name")[0].value = null;
    document.getElementsByName("Mobile")[0].value = null;
    document.getElementsByName("Email")[0].value = null;
    document.getElementsByName("Income")[0].value = null;
    document.getElementsByName("DOB")[0].value = null;
    document.getElementsByName("amount")[0].value = null;

    document.getElementsByName("file1")[0].value = null;
    document.getElementsByName("file2")[0].value = null;
    document.getElementsByName("file3")[0].value = null;
    document.getElementsByName("file4")[0].value = null;
    document.getElementsByName("file5")[0].value = null;
    document.getElementsByName("file6")[0].value = null;
    document.getElementsByName("file7")[0].value = null;
    document.getElementsByName("file8")[0].value = null;

    toast.success("Successfully uploaded");
  }
  
  sendEmail(){
    emailjs.send("service_y8s8n01","template_c6t1g42",{
      to_name: "info@loankaaran.com",
      from_name: "loankaaran",
      message: "We received a file from Mr./Mrs." + document.getElementsByName("Name")[0].value + "! \n Mobile : " + document.getElementsByName("Mobile")[0].value + "\n Email : " + document.getElementsByName("Email")[0].value + "\n Income : " + document.getElementsByName("Income")[0].value + "\n Required Amount : " + document.getElementsByName("amount")[0].value ,
    },'rjtmRxPPD5Klv50tr');
  }

 

   
  render() {
    
    

    const sendEmail = (e) => {
      e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

      

    // emailjs.sendForm('service_y8s8n01', 'template_c6t1g42', e.target, 'rjtmRxPPD5Klv50tr')
    // .then((result) => {
    //   window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    //   }, (error) => {
    //     toast.error(error.text);
    //   });

    };

    return (
      <section id="Upload">
            <h1> Apply Loan </h1>
           
        <form  onSubmit={sendEmail}>
          <div class="form-group">
            <div className="row">
                <div className="six columns">   
                  <div>
                    <label htmlFor="Name">
                      Name <span className="required">*</span>
                    </label>
                    <input type="text" class="form-control" defaultValue="" size="20" id="Name" name="Name" onChange={this.handleInputChange} />
                  </div>

                  <div>
                    <label htmlFor="Mobile">Mobile</label>
                    <input type="text" class="form-control" defaultValue="" size="10" id="Mobile" name="Mobile" onChange={this.handleChange} />
                  </div>

                  <div>
                    <label htmlFor="amount">Income Per Month <span className="required">*</span></label>
                    
                    <input type="text" class="form-control" defaultValue="" size="10" id="Income" name="Income" onChange={this.handleChange} />
                  </div>
                  
                  <label>Aadhar : </label>
                  <input type="file" id="formFile" className="form-control" multiple name="file1" onChange={this.handleAadharInputChange}  />
                  <label>PAN : </label>
                  <input type="file" id="formFile" className="form-control" multiple name="file2" onChange={this.handlePanInputChange}  />
                  <label>Photograph : </label>
                  <input type="file" id="formFile" className="form-control" multiple name="file3" onChange={this.handlePhotoInputChange}  /> 
                  <label>Bank Statement : </label>
                  <input type="file" id="formFile" className="form-control" multiple name="file4" onChange={this.handleBankInputChange}  />                
                </div>
                <div class="six columns">
                  <div>
                    <label htmlFor="DOB">
                      Date of Birth <span className="required">*</span>
                    </label>
                    <input type="text" defaultValue="" size="10" id="DOB" name="DOB" onChange={this.handleChange} />
                  </div>
                  <div>
                    <label htmlFor="Email">Email</label>
                    <input type="text" defaultValue="" size="10" id="Email" name="Email" onChange={this.handleChange} />
                  </div>
                  <div>
                    <label htmlFor="amount">Required Amount <span className="required">*</span></label> 
                    <input type="text" defaultValue="" size="10" id="amount" name="amount" onChange={this.handleChange} />
                  </div>
                  
                        <label>Payslip 1 : </label>
                        <input type="file" className="form-control" multiple name="file5" onChange={this.handlePay1InputChange}  />
                     
                        <label>Payslip 2 : </label>
                        <input type="file" className="form-control" multiple name="file6" onChange={this.handlePay2InputChange}  />
                     
                        <label>Payslip 3 : </label>
                        <input type="file" className="form-control" multiple name="file7" onChange={this.handlePay3InputChange}  />

                        <label>Others : </label>
                        <input type="file" className="form-control" multiple name="file8" onChange={this.handleOtherInputChange}  />

                        <br></br><br></br>
                        <div className="form-row">
                    <div className="col-md-6">
                      <button type="button" className="btn btn-success" onClick={() => this.onSubmit()} >
                        Upload File
                      </button>
                    </div>
                 </div>
              <br />
            { this.state.responseArray.map((res, i) => (
              <div key={i}>
                <div  className={'img-alert alert alert-'+res.status}>
                  <div>{res.message} : {res.url}</div>
                  <img src={res.base64} />
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
      </form>
        
        
        <div className="row">
          
            <img  src ="images/upload2.jpg" ></img>
          
        </div>
       
          <br />
          
       
      </section>
    );
  }
}

export default Upload;
