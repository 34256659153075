import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

let id = 0;
class banks extends Component {
  render() {
    if (!this.props.data) return null;
    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/banks/" + projects.image;

      return (
        <section id="banks">
         <div key={id++} className="columns banks-item">
            <div className="item-wrap">
              <img alt={projects.title} src={projectImage} />
              <div style={{ textAlign: "center" }}>{projects.title}</div> 
            </div>
          </div> 
          

        </section>
      );
    });

   
    return (
      <section id="Banks">
        <Fade left duration={1000} distance="40px">
         
              <h1>Our Authorized Partners</h1>

              {/* <div
                id="Banks-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
              {projects}
              </div> */}

              <div className="row" >
                <div className="three columns" > <img src="images/banks/01.png" /></div>
                <div className="three columns" > <img src="images/banks/02.png" /></div>
                <div className="three columns" > <img src="images/banks/03.png" /></div>
                <div className="three columns" > <img src="images/banks/05.png" /></div>
                <div className="three columns" > <img src="images/banks/06.png" /></div>
                <div className="three columns" > <img src="images/banks/07.png" /></div>
                <div className="three columns" > <img src="images/banks/08.png" /></div>
                <div className="three columns" > <img src="images/banks/09.png" /></div>
                <div className="three columns" > <img src="images/banks/12.png" /></div>
                <div className="three columns" > <img src="images/banks/11.png" /></div>
                <div className="three columns" > <img src="images/banks/10.png" /></div>
                <div className="three columns" > <img src="images/banks/13.png" /></div>
                <div className="three columns" > <img src="images/banks/14.png" /></div>
                <div className="three columns" > <img src="images/banks/15.png" /></div>
                <div className="three columns" > <img src="images/banks/16.png" /></div>
                <div className="three columns" > <img src="images/banks/17.png" /></div>
                <div className="three columns" > <img src="images/banks/18.png" /></div>
                <div className="three columns" > <img src="images/banks/19.png" /></div>
                <div className="three columns" > <img src="images/banks/20.png" /></div>
                <div className="three columns" > <img src="images/banks/21.png" /></div>
                <div className="three columns" > <img src="images/banks/22.png" /></div>
                <div className="three columns" > <img src="images/banks/23.png" /></div>
                <div className="three columns" > <img src="images/banks/24.png" /></div>
                <div className="three columns" > <img src="images/banks/25.png" /></div>
              </div>

          
        </Fade>
      </section>
    );
  }
}

export default banks;
