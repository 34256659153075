import React, { Component } from "react";
import Fade from "react-reveal";
import CountUp from "react-countup";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
       
        <Fade duration={1000}>
          <div className="row" >
         
            {/* <div className="three columns" >
              <img src="images/page/AboutUs.JPG" />
            </div> */}
            <div className="twelve columns main-col">
              <h2>About Us</h2>
                          <p>{bio}</p>
              <div class="ltn__counterup-area section-bg-1 pt-30 pb-10">
<div class="container">
                  <div class="row">
                    <div class="four columns">
                      <div class="ltn__counterup-item text-color-white---">
                        <div class="counter-icon">
                          <i class="flaticon-select"></i>
                        </div>
                        <h1><CountUp start={0} end={2500} duration={2.75}
                              useEasing={true}
                              useGrouping={true}
                                separator=" "
                                decimals={0}
                                      decimal=""
                                      prefix=""
                                suffix=" " /><span class="counter animated fadeInDownBig"></span><span class="counterUp-icon">+</span> </h1>
                        <h6>Satisfied Cutomers</h6>
                      </div>
                    </div>
                    <div class="four columns">
                      <div class="ltn__counterup-item text-color-white---">
                        <div class="counter-icon">
                          <i class="flaticon-office"></i>
                        </div>
                        <h1>
                          <CountUp start={0} end={5} duration={2.75}
                              useEasing={true}
                              useGrouping={true}
                                separator=" "
                                decimals={2}
                                      decimal=""
                                      prefix=""
                                suffix=" "
                                // onComplete={onComplete}
                                //  onStart={onStart}
                                 /><span class="counter animated fadeInDownBig"></span><span class="counterUp-letter">Crs </span><span class="counterUp-icon">+</span> </h1>
                        <h6>Dispersed Loans</h6>
                      </div>
                    </div>
                    <div class="four columns">
                      <div class="ltn__counterup-item text-color-white---">
                        <div class="counter-icon">
                          <i class="flaticon-excavator"></i>
                        </div>
                       <h1>
                        <CountUp start={0} end={5} duration={2.75}
                              useEasing={true}
                              useGrouping={true}
                                separator=" "
                                decimals={0}
                                      decimal=""
                                      prefix=""
                                suffix=" " />
                        <span class="counter animated fadeInDownBig"></span>
                       <span class="counterUp-icon">+</span> </h1>
                       <h6>years of service</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div><br></br>
              <div className="row">
              
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
            
               
                
              </div>
            </div>
          </div>
         
        </Fade>
      </section>
    );
  }
}

export default About;
